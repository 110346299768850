import { Divider, Grid, Heading } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import {
	ArticlePageStoryblok,
	BlogPostStoryblok,
	CardArticleGroupStoryblok,
	CompanyGuidePostStoryblok,
} from '@/components';
import { truncateButWholeWords } from '@/utils';
import { normalizeFullSlug } from '@/utils/storyblok';
import { ISbStoryData } from 'storyblok-js-client';
import { t } from 'ttag';
import { CardArticle, CardArticleProps } from '../CardArticle/CardArticle';
import styles from './CardArticleGroup.module.scss';

interface BlokProps {
	blok: CardArticleGroupStoryblok;
}

const blokProps = ({ blok }: BlokProps): CardArticleGroupProps => {
	const blokArticles = blok.articles as unknown as ISbStoryData<
		BlogPostStoryblok | ArticlePageStoryblok | CompanyGuidePostStoryblok
	>[];

	const articles = blokArticles
		.map((article) => {
			if (article.content.component === 'ArticlePage') {
				return {
					title: article?.content?.cardTitle || article.content.title || '',
					text: `${truncateButWholeWords(article?.content?.cardShortText || article.content.intro || '', 90)}...`,
					image: article?.content?.cardImage?.filename ? article?.content?.cardImage : article.content.image,
					label: article?.content?.categoryTag,
					linkButton: {
						href: normalizeFullSlug(article?.full_slug) ?? '',
						children: t`Läs mer`,
					},
				};
			}
			if (article.content.component === 'BlogPost') {
				return {
					title: article?.content?.cardTitle || article.content.header[0].h1Part1 || '',
					text: `${truncateButWholeWords(article?.content?.cardShortText || article.content.intro || '', 90)}...`,
					image: article?.content?.cardImage?.filename ? article?.content?.cardImage : article.content.header[0].image,
					linkButton: {
						href: normalizeFullSlug(article?.full_slug) ?? '',
						children: t`Läs mer`,
					},
				};
			}
			if (article.content.component === 'CompanyGuidePost') {
				return {
					title: article?.content?.cardTitle || article.content.header[0].h1Part1 || '',
					text: `${truncateButWholeWords(article?.content?.cardShortText || article.content.intro || '', 90)}...`,
					image: article?.content?.cardImage?.filename ? article?.content?.cardImage : article.content.header[0].image,
					linkButton: { href: normalizeFullSlug(article?.full_slug) ?? '', children: t`Läs mer` },
				};
			}
		})
		.filter((article) => article !== undefined);

	return {
		title: blok.title,
		cards: articles,
		linkButton: blok.linkButton?.[0] && LinkButton.blokProps({ blok: blok.linkButton[0] }),
		_editable: blok._editable,
	};
};

export interface CardArticleGroupProps {
	title?: string;
	cards: CardArticleProps[];
	linkButton?: LinkButtonProps;
	className?: string;
	_editable?: string;
}

export const CardArticleGroup: Blok<CardArticleGroupProps, BlokProps> = ({ title, cards, className, linkButton }) => {
	return (
		<div className={className}>
			{title && (
				<div className={styles.titleContainer}>
					<Heading title={title} as="div" size="h5" marginBottom="xs" exo2 />
					<Divider margin="xs" />
				</div>
			)}
			<Grid className={styles.grid} columns={{ base: 1, md: 2, lg: 3 }} colGap="3xl" rowGap="xl">
				{cards.map((card: CardArticleProps) => {
					return (
						<Grid.Item key={card._uid}>
							<CardArticle {...card} />
						</Grid.Item>
					);
				})}
			</Grid>

			{linkButton && (
				<div className={styles.linkContainer}>
					<LinkButton {...linkButton} variant="text" />
				</div>
			)}
		</div>
	);
};
CardArticleGroup.blokProps = blokProps;
