import { IconButton } from '@/atoms';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './IntegrationCarousel.module.scss';

interface CarouselProps {
	carouselItems: JSX.Element[];
	singleSlide?: boolean;
	showNavigation?: boolean;
	showDots?: boolean;
	startItem?: number;
	testId?: string;
	className?: string;
}

interface CarouselItemProps {
	order: number;
	className?: string;
	onClick?: () => void;
	testId?: string;
	[key: string]: unknown;
}

const CarouselItem: React.FC<PropsWithChildren<CarouselItemProps>> = ({
	children,
	testId,
	className,
	order,
	onClick,
}) => {
	return (
		<div
			data-test-id={testId}
			className={className}
			onClick={onClick}
			data-test-carousel-order={order}
			style={{ order: order } as React.CSSProperties}
		>
			{children}
		</div>
	);
};

export const IntegrationCarousel: React.FC<CarouselProps> = ({
	carouselItems,
	singleSlide = false,
	showNavigation = true,
	showDots = true,
	startItem = 0,
	testId,
	className,
}) => {
	const [items, setItems] = useState<JSX.Element[]>([]);
	const [currentItem, setCurrentItem] = useState(startItem);

	const [animateLeft, setAnimateLeft] = useState(false);
	const [animateRight, setAnimateRight] = useState(false);

	const [touchStartX, setTouchStartX] = useState(0);
	const [touchEndX, setTouchEndX] = useState(0);

	const transitionSpeed = 500;
	const swipeThreshold = 50;

	useEffect(() => {
		const itemsClones = [...carouselItems, ...carouselItems];

		const itemsWithKeys = itemsClones.map((item, index) => React.cloneElement(item, { key: index }));

		if (!singleSlide) {
			rotateArray(itemsWithKeys, -1);
		}

		setItems(itemsWithKeys);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	if (!carouselItems.length) return null;

	const rotateArray = (array: JSX.Element[], n: number) => {
		for (let i = 0; i < Math.abs(n); i++) {
			if (n > 0) {
				array.push(array.shift() as JSX.Element);
			} else {
				array.unshift(array.pop() as JSX.Element);
			}
		}
	};

	const previous = () => {
		setAnimateRight(true);
		setTimeout(() => {
			setCurrentItem((prev) => prev - 1);
			setAnimateRight(false);
		}, transitionSpeed);
	};

	const next = () => {
		setAnimateLeft(true);
		setTimeout(() => {
			setCurrentItem((prev) => prev + 1);
			setAnimateLeft(false);
		}, transitionSpeed);
	};

	const calculateOrder = (index: number) => (((index - currentItem) % items.length) + items.length) % items.length;

	const shouldShowNavigation =
		showNavigation && (carouselItems.length > 2 || (singleSlide && carouselItems.length > 1));

	const handleTouchStart = (e: React.TouchEvent) => {
		setTouchStartX(e.touches[0].clientX);
	};

	const handleTouchMove = (e: React.TouchEvent) => {
		setTouchEndX(e.touches[0].clientX);
	};

	const handleTouchEnd = () => {
		const swipeDistance = touchStartX - touchEndX;

		if (swipeDistance > swipeThreshold) {
			next();
		} else if (swipeDistance < -swipeThreshold) {
			previous();
		}
	};

	const handleNavigationClick = (direction: 'next' | 'prev') => {
		if (direction === 'next') {
			next();
		} else {
			previous();
		}
	};

	return (
		<div
			className={classNames([styles.carousel, className])}
			data-test-id={testId}
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
		>
			{shouldShowNavigation && (
				<div className={styles.carouselControls} data-test-id="carousel-navigation-arrows">
					<IconButton
						iconSize="2x"
						aria-label="navigate left"
						iconName="chevron-left"
						onClick={() => handleNavigationClick('prev')}
						testID={'carousel-previous-button'}
					/>
					<IconButton
						iconSize="2x"
						aria-label="navigate right"
						iconName="chevron-right"
						onClick={() => handleNavigationClick('next')}
						testID={'carousel-next-button'}
					/>
				</div>
			)}

			<div className={styles.carouselInner}>
				<div className={classNames([styles.carouselItems, singleSlide ? styles.single : null])}>
					{items.map((item, index) => {
						return (
							<CarouselItem
								key={`carousel-item-${item.key}`}
								testId={`carousel-item-${index}`}
								order={calculateOrder(index)}
								className={classNames([
									styles.itemOrder,
									styles.carouselItem,
									singleSlide ? styles.single : null,
									animateLeft ? styles.animateLeft : null,
									animateRight ? styles.animateRight : null,
								])}
							>
								{item}
							</CarouselItem>
						);
					})}
				</div>
				{showDots && carouselItems.length > 1 && (
					<div className={styles.carouselDots}>
						{carouselItems.map((_, index) => (
							<div
								key={`carousel-dot-${index}`}
								className={classNames([
									styles.dot,
									currentItem % carouselItems.length === index ? styles.active : null,
								])}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
