import { ContainerFluid, Grid, Heading, Section } from '@/atoms';
import { Blok } from '@/bloks/Blok';
import { LinkButton, LinkButtonProps } from '@/bloks/Button';
import { CardAttract, CardAttractProps } from '@/bloks/Card';
import { SectionAttractLargeStoryblok, ThemeV2Storyblok } from '@/components';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { ThemeProvider } from '@/contexts/theme';
import { ImageAsset, ImageAssetProps } from '@/metabloks';
import { getThemeInfo } from '@/themes';
import { cls } from '@/utils';
import { editableBlok } from '@/utils/storyblok';
import styles from './SectionAttractLarge.module.scss';

interface BlokProps {
	blok: SectionAttractLargeStoryblok;
}

const blokProps = ({ blok }: BlokProps): Props => ({
	image: blok.image,
	mobileImage: blok.mobileImage,
	title: blok.title,
	cards: blok.cards?.map((card) => CardAttract.blokProps({ blok: card })),
	linkButtons: blok.linkButtons?.map((btn) => LinkButton.blokProps({ blok: btn })),
	layout: blok.layout ?? '50-50',
	theme: blok?.theme?.[0]?.theme,
	_editable: blok._editable,
});

interface Props {
	image?: ImageAssetProps;
	mobileImage?: ImageAssetProps;
	title: string;
	cards: CardAttractProps[];
	linkButtons?: LinkButtonProps[];
	layout: '50-50' | '33-66';
	theme?: ThemeV2Storyblok['theme'];
	_editable?: string;
}

export const SectionAttractLarge: Blok<Props, BlokProps> = ({
	image,
	mobileImage,
	title,
	cards,
	linkButtons,
	layout,
	theme = 'white',
	_editable,
}) => {
	const innerThemeInfo = getThemeInfo(theme);
	const { hasSubMenu } = useSubMenu();
	const outerGridCols =
		layout === '50-50' ? { base: 1, md: 1, lg: 2 } : { base: 1, md: 1, lg: '1fr 2fr', xl: '1fr 2fr' };
	const cardGridCols = layout === '50-50' ? { base: 1, md: 2, lg: 2 } : { base: 1, md: 2, lg: 2, xl: 3 };

	return (
		<ContainerFluid
			{...editableBlok({ _editable })}
			theme={hasSubMenu ? 'white' : theme}
			className={cls(layout === '50-50' && styles[`layout--50-50`], hasSubMenu && styles.hasSubMenu)}
		>
			<Section padding={!hasSubMenu ? { base: 'none' } : undefined}>
				<ThemeProvider theme={theme}>
					<Grid columns={outerGridCols} className={cls(styles.section, innerThemeInfo.styles.bgColor)}>
						<Grid.Item className={cls(mobileImage?.filename && styles.hasMobileImage)}>
							{image?.filename && (
								<div className={styles.image}>
									<ImageAsset asset={image} objectPosition="center center" absolute />
								</div>
							)}

							{mobileImage?.filename && (
								<div className={styles.mobileImage}>
									<ImageAsset asset={mobileImage} objectPosition="center center" absolute />
								</div>
							)}
						</Grid.Item>
						<Grid.Item>
							<div className={styles.inner}>
								<Heading as="h2" size="h3" title={title} marginBottom="3xl" />

								<Grid rowGap="3xl" columns={cardGridCols} className={styles.cardGrid}>
									{cards.map((card) => (
										<Grid.Item key={card._uid}>
											<CardAttract {...card} />
										</Grid.Item>
									))}
								</Grid>

								{!!linkButtons?.length && (
									<div className={styles.btnWrapper}>
										{linkButtons.map((btn, index) => (
											<LinkButton {...btn} key={btn._uid} variant={index === 0 ? 'filled' : 'text'} />
										))}
									</div>
								)}
							</div>
						</Grid.Item>
					</Grid>
				</ThemeProvider>
			</Section>
		</ContainerFluid>
	);
};

SectionAttractLarge.blokProps = blokProps;
