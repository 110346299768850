import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { useTheme } from '@/contexts/theme';
import { cls } from '@/utils';
import styles from './Section.module.scss';

export type Padding =
	| 'none'
	| '3xs'
	| '2xs'
	| 'xs'
	| 'sm'
	| 'md'
	| 'lg'
	| 'xl'
	| '2xl'
	| '3xl'
	| '4xl'
	| '5xl'
	| '6xl'
	| '7xl'
	| '8xl'
	| '9xl';

const sizes = ['max', 'xl', 'lg', 'md', 'sm', 'min', 'base'] as const;

export type PaddingSizes = {
	base: Padding;
	min?: Padding;
	sm?: Padding;
	md?: Padding;
	lg?: Padding;
	xl?: Padding;
	max?: Padding;
};

interface SectionProps {
	children: React.ReactNode;
	/** Optionally render as another element than `<section>` */
	as?: 'section' | 'div' | 'header' | 'footer' | 'article';
	className?: string;
	id?: string;
	maxWidth?: '1920px' | '1440px' | '1296px' | '1040px' | '925px' | '832px' | '640px' | '2561px' | '1120px' | 'custom';
	lang?: 'sv' | 'en';

	/**
	 * Equal padding to all four sides of an element.
	 */
	padding?: PaddingSizes;

	/**
	 * Padding to left and right of element.
	 */
	paddingX?: PaddingSizes;

	/**
	 * Padding to top and bottom of element.
	 */
	paddingY?: PaddingSizes;
}

/**
 * A section of the page. By default restricted to a max width 1920px, keeping
 * to the center, add a maxWidth prop if for example 1440px maxWidth is wanted instead.
 */
export const Section: React.FC<SectionProps> = ({
	as: Component = 'section',
	children,
	id,
	className,
	maxWidth: maxWidthRaw = '1920px',
	padding,
	paddingX,
	paddingY,
	...rest
}) => {
	const maxWidth = maxWidthRaw !== 'custom' ? parseInt(maxWidthRaw) : undefined;
	const style: Record<string, string | number | null | undefined> = {};
	const { hasSubMenuV1, hasSubMenuV2 } = useSubMenu();
	const themeInfo = useTheme();

	if (!padding && !paddingX) {
		paddingX = { base: 'xl', md: '3xl', lg: '5xl' };

		if (hasSubMenuV1) {
			paddingX = { base: 'xl', md: '3xl', lg: '3xl' };
		}

		if (hasSubMenuV2 && themeInfo.name === 'white') {
			paddingX = { base: 'md', md: '3xl', lg: 'none' };
		}
	}

	if (!padding && !paddingY) {
		paddingY = { base: '3xl', md: '4xl', lg: '5xl', max: themeInfo?.name === 'white' ? 'none' : '5xl' };

		if (hasSubMenuV1) {
			paddingY = { base: '3xl', md: '3xl' };
		}

		if (hasSubMenuV2 && themeInfo.name === 'white') {
			paddingY = { base: 'xl', md: '3xl' };
		}
	}

	for (const size of sizes) {
		if (size) {
			if (padding) {
				style[`--padding-${size}`] = `var(--space-inset-${padding[size]!})`;
			}

			if (!padding && paddingX) {
				style[`--padding-x-${size}`] = `var(--space-inset-${paddingX[size]!})`;
			}

			if (!padding && paddingY) {
				style[`--padding-y-${size}`] = `var(--space-inset-${paddingY[size]!})`;
			}
		}
	}

	return (
		<Component
			id={id}
			className={cls(
				styles.section,
				!padding && paddingX && styles['padding-x'],
				!padding && paddingY && styles['padding-y'],
				className,
			)}
			style={{ ...style, maxWidth }}
			{...rest}
		>
			{children}
		</Component>
	);
};
